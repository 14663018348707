<template>
  <div class="filters" v-show="visible">
    <Select
      placeholder="Выберите категорию"
      v-model="filters.course_category_id"
      :loading="!categoryOptions.length"
    >
      <Option
        v-for="option of categoryOptions"
        :key="option.name"
        :value="option.id"
        :label="option.name"
      >
        {{ option.name }}
      </Option>
    </Select>

    <Select
      placeholder="Выберите статус"
      v-model="filters.status"
    >
      <Option
        v-for="option of statusOptions"
        :key="option.name"
        :value="option.value"
        :label="option.name"
      >
        {{ option.name }}
      </Option>
    </Select>

    <Select
      placeholder="Сортировка"
      v-model="filters.order_by"
    >
      <Option
        v-for="option of orderByOptions"
        :key="option.name"
        :value="option.value"
        :label="option.name"
      >
        {{ option.name }}
      </Option>
    </Select>
  </div>
</template>

<script setup>
import Select from '@/components/common/Select/index.vue'
import Option from '@/components/common/Select/Option.vue'

import { ref, onMounted, reactive, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import debounce from '@/helpers/debounce'
import { getCourseCategories } from '@/actions/uiBusiness/courses'

defineProps({
  visible: Boolean
})

const route = useRoute()
const router = useRouter()

const filters = reactive({
  course_category_id: null,
  order_by: null,
  status: null
})

watch(
  () => filters,
  debounce(() => router.push({ query: { ...route.query, ...filters } })),
  {
    deep: true
  }
)

onMounted(async () => {
  categoryOptions.value = (await getCourseCategories()).data
})

const categoryOptions = ref([])
const statusOptions = [
  {
    name: 'Опубликовано',
    value: 'published'
  },
  {
    name: 'Черновик',
    value: 'draft'
  }
]

const orderByOptions = [
  {
    name: 'По названию',
    value: 'name'
  },
  {
    name: 'По добавлению',
    value: 'id'
  },
  {
    name: 'По продажам',
    value: 'sales_count'
  }
]
</script>

<style scoped>
.filters {
  display: flex;
  align-items: flex-end;
  gap: 20px;
  margin-bottom: 20px;
  padding: 0 20px;
}
.filters > div{
  max-width: 200px;
}
.v-enter-active,
.v-leave-active {
  transition: all 0.3s ease;
}
.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
