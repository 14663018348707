<template>
  <Form
    class="newSchoolForm"
    @finish="asyncNewSchool"
  >
    <Input
      type="text"
      placeholder="Имя компаний"
      required
      v-model="formData.name"
    />
    <Input
      type="textarea"
      placeholder="Введите описание школы"
      v-model="formData.description"
    />
    <Upload class="preview" v-model="formData.school_logo" :height="301" :width="570"/>
    <Button htmlType="submit" size="large" name="Зарегистрировать школу" :loading="isLoading"/>
  </Form>
</template>

<script setup>
import Upload from '@/components/common/Upload.vue'
import Form from '@/components/common/Form.vue'

import { reactive, ref } from 'vue'
import { useRouter } from 'vue-router'

import { createNewSchool } from '@/actions/uiBusiness/schools'

const router = useRouter()

const isLoading = ref(false)
const formData = reactive({
  name: '',
  description: '',
  school_logo: null
})

const asyncNewSchool = async () => {
  isLoading.value = true
  const response = await createNewSchool(formData)

  localStorage.setItem('selected_school', JSON.stringify(response.data))
  router.push(`/school/${response.data.id}`)
}
</script>

<style>
.newSchoolForm form {
  display: grid;
  gap: 10px;
  align-items: center;
}
.newSchoolForm form.form-login {
  min-width: 500px;
}
.newSchoolForm textarea {
  height: 90px;
}
.newSchoolForm .preview {
  height: 301px;
  width: auto;
}
</style>
