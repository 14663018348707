<template>
  <div class="filters" v-show="visible">
    <Select
      v-model="filters.finished"
      :options="statusOptions"
      optionNameKey="name"
      optionValueKey="value"
      placeholder="Выберите статус"
    />
  </div>
</template>

<script setup>
import Select from '@/components/common/Select.vue'
import { reactive, watch } from 'vue'

import { useRoute, useRouter } from 'vue-router'
import debounce from '@/helpers/debounce'

defineProps({
  visible: Boolean
})

const route = useRoute()
const router = useRouter()

const filters = reactive({
  finished: null,
  order_by: 'finished_at',
  descending: 0
})

watch(
  () => filters,
  debounce(() => router.push({ query: { ...route.query, ...filters } })),
  {
    deep: true
  }
)

const statusOptions = [
  {
    name: 'Завершенный',
    value: 1
  },
  {
    name: 'Не завершенный',
    value: 0
  }
]
</script>

<style scoped>
.filters {
  display: flex;
  align-items: flex-end;
  gap: 20px;
  margin-bottom: 20px;
}
.filters > div:first-child {
  width: 210px;
}
</style>
