<template>
  <div class="input"
    :class="{
      focused: input.focused,
      labeled: input.focused || input.value,
      withError: input.withError && input.focused || input.withError && input.value,
      hasError: input.withError,
      disabled,
      static: staticPlaceholder
    }">

    <span class="placeholder" v-if="type !== 'editor' && !staticPlaceholder">{{ placeholder }}</span>

    <input
      :type="input.eyeState ? input.eyeState : type"
      v-model="input.value"
      :required="required"
      @change="$emit('change')"
      @blur="onBlur"
      @focus="onFocus"
      :class="{'with-error': input.withError}"
      v-mask="'+7 (###) ### ####'"
      :autofocus="autofocus"
      :autocomplete="autocomplete"
      :placeholder="staticPlaceholder ? placeholder : ''"
      v-if="type === 'tel'"
    >

    <textarea
      v-model="input.value"
      :required="required"
      @change="$emit('change')"
      @blur="onBlur"
      @focus="onFocus"
      :class="{'with-error': input.withError}"
      :placeholder="staticPlaceholder ? placeholder : ''"
      v-else-if="type === 'textarea'"
    />

    <select
      v-model="input.value"
      :required="required"
      :multiple="multiple"
      @change="$emit('change')"
      @blur="onBlur"
      @focus="onFocus"
      :class="{'with-error': input.withError}"
      v-else-if="type === 'select'"
    >
      <option
        :value="optionKey ? option[optionKey] : option"
        v-for="option of options"
        :key="option"
        :selected="option === selected"
      >
        {{ optionName ? option[optionName] : option }}
      </option>
    </select>

    <CKEditor
      v-model="input.value"
      :placeholder="placeholder"
      :required="required"
      @change="$emit('change')"
      @blur="onBlur"
      @focus="onFocus"
      :class="{'with-error': input.withError}"
      v-else-if="type === 'editor'"
    />

    <div
      class="disabled-input"
      v-else-if="type === 'disabled'"
    >
      <span v-if="input.value">
        {{ input.value }}
      </span>
    </div>

    <input
      :type="input.eyeState ? input.eyeState : type"
      v-model="input.value"
      :required="required"
      :min="min"
      @blur="onBlur"
      @focus="onFocus"
      @change="$emit('change')"
      :class="{'with-error': input.withError}"
      :autocomplete="autocomplete"
      :autofocus="autofocus"
      :placeholder="staticPlaceholder ? placeholder : ''"
      :disabled="disabled"
      v-else
    >

    <span v-if="type === 'password'" class="input__eye">
      <Transition mode="out-in">
        <SvgIcon
          v-if="input.eyeState === 'text'"
          name="password-eye-on"
          :height="24"
          :width="24"
          :viewboxHeight="56"
          :viewboxWidth="56"
          @click="input.eyeState = 'password'"
          :fill="input.withError ? 'var(--error-color)' : (input.focused ? 'var(--accent-color)' : 'black')"
        />
        <SvgIcon
          v-else
          name="password-eye-off"
          :height="24"
          :width="24"
          :viewboxHeight="56"
          :viewboxWidth="56"
          @click="input.eyeState = 'text'"
          :fill="input.withError ? 'var(--error-color)' : (input.focused ? 'var(--accent-color)' : 'black')"
        />
      </Transition>
    </span>
    <SvgIcon
      v-if="resetButton && input.value"
      class="reset"
      name="x"
      :height="14"
      :width="14"
      :viewboxHeight="14"
      :viewboxWidth="14"
      @click="$emit('reset')"
    />
    <Transition name="error">
      <span class="error" :class="{ long: input.withError.length > 20 }" v-if="input.withError">{{ input.withError }}</span>
    </Transition>
  </div>
</template>

<script setup>
import { inject, onMounted, reactive, watch } from 'vue-demi'
import CKEditor from '@/components/common/CKEditor.vue'

const props = defineProps({
  modelValue: {
    type: [String, Number, Array],
    required: false
  },
  value: {
    type: [String, Number],
    required: false
  },
  name: {
    type: String,
    required: false
  },
  type: {
    type: String,
    default: 'text',
    validator: (value) => ['text', 'tel', 'email', 'number', 'select', 'editor', 'password', 'textarea', 'disabled'].includes(value)
  },
  placeholder: {
    type: String,
    required: false
  },
  required: {
    type: Boolean,
    default: false
  },
  validation: {
    type: [String, Object, Boolean],
    default: false
  },
  sameAs: {
    type: String,
    required: false
  },
  options: {
    type: Array,
    default: () => []
  },
  optionKey: {
    type: String,
    required: false
  },
  optionName: {
    type: String,
    required: false
  },
  selected: {
    type: [String, Number],
    required: false
  },
  autocomplete: {
    type: String,
    default: 'on'
  },
  autofocus: {
    type: Boolean,
    default: false
  },
  min: {
    type: Number,
    required: false
  },
  staticPlaceholder: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  },
  resetButton: {
    type: Boolean,
    default: false
  },
  multiple: {
    type: Boolean,
    default: false
  },
  errorText: {
    type: String,
    required: false
  }
})
const emit = defineEmits([
  'update:modelValue',
  'reset',
  'blur',
  'error',
  'success',
  'change',
  'focus',
  'label'
])
const input = reactive({
  value: props.modelValue,
  isDirty: false,
  eyeState: null,
  withError: false,
  focused: false
})
const hasError = (props.required || props.validation) && props.name ? inject('hasError') : {}
if (props.name) hasError[props.name] = props.required || props.validation
const inputName = props.name ?? 'example'

const formValue = props.name ? inject('formData') : {}

const rules = {
  email: email => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    .test(String(email).toLowerCase()) ? false : 'Введите правильную почту',

  phone: phone => phone && phone.length === 17 ? false : 'Введите правильный номер',

  password: password => /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/
    .test(String(password)) ? false : 'Пароль должен содержать минимум 8 символов. И в нем должны быть минимум, одна цифра,одна большая буква и одна маленькая буква',

  sameAs: string => string === props.sameAs ? false : 'Поля не совпадают',

  customRegex: string => props.validation.test(String(string)) ? false : props.errorText ?? 'Введите поле правильно'
}

const onChangeValue = () => {
  formValue[inputName] = input.value
  const validate = validation()
  if (input.isDirty) {
    input.withError = false
    if (props.required) {
      if (!input.value) input.withError = 'Это поле обязателно'
    }
    if (props.validation && !input.withError) {
      input.withError = validate(input.value)
    }
  }
  if (props.validation && !validate(input.value)) {
    emit('success')
    hasError[inputName] = false
  } else if (props.validation && validate(input.value)) {
    emit('error')
    hasError[inputName] = true
  } else if (props.required && input.value) {
    emit('success')
    hasError[inputName] = false
  } else {
    emit('error')
    hasError[inputName] = true
  }
  if (props.min && input.value < props.min) input.value = props.min
  emit('update:modelValue', input.value)
}

watch(
  () => input.value,
  onChangeValue
)

watch(
  () => props.modelValue,
  () => {
    input.value = props.modelValue
    onChangeValue()
  }
)

onMounted(onChangeValue)

watch(
  () => input.withError,
  () => {
    if (input.withError) {
      emit('error')
      hasError[inputName] = true
    } else {
      emit('success')
      hasError[inputName] = false
    }
  }
)

watch(
  () => [input.focused, input.value],
  () => {
    if (input.focused || input.value) {
      emit('label', true)
    } else emit('label', false)
  }
)

const validation = () => {
  if (typeof props.validation === 'string') {
    return rules[props.validation]
  } else if (typeof props.validation === 'object') {
    return rules.customRegex
  }
  return () => {}
}

const onBlur = e => {
  emit('blur', e)
  input.focused = false

  if (!input.isDirty) input.isDirty = true
  input.withError = false
  if (props.required && !input.value) {
    input.withError = 'Это поле обязателно'
  }

  if (props.validation && !input.withError) {
    const validate = validation()
    input.withError = validate(input.value)
  }
}

const onFocus = e => {
  input.focused = true
  emit('focus', e)
}
</script>

<style scoped>
.input {
  position: relative;
  width: 100%;
  align-items: flex-start!important;
  transition: all .2s ease;
  margin-bottom: 10px;
}
.placeholder {
  font-weight: 400;
  font-size: 14px;
  line-height: 16.71px;
  color: #16193180;
  position: absolute;
  z-index: 1;
  top: 16px;
  left: 18px;
  transition: all .2s ease;
  pointer-events: none;
}
.input.hasError {
  margin-bottom: 15px;
}
.input.labeled,
.input.focused,
.input.withError {
  margin-top: 10px;
}
.input.static {
  margin-top: 0;
}
.input.labeled .placeholder {
  left: 0;
  top: -20px;
  z-index: 0;
  line-height: 17px;
  color: var(--primary-color);
}
.input.focused .placeholder {
  color: var(--accent-color);
  left: 0;
  top: -20px;
  z-index: 0;
  line-height: 17px;
}
.input.withError .placeholder {
  color: var(--error-color)!important;
}
.error {
  color: var(--error-color);
  font-weight: 400;
  font-size: 14px;
  min-height: 1em;
  position: absolute;
  right: 0;
  bottom: -20px;
  text-align: end;
}
.error.long {
  position: relative;
  display: block;
  bottom: -1px;
  margin-bottom: -10px;
  max-width: 36em;
}
input, textarea, select {
  border: 1px solid var(--primary-color);
  box-sizing: border-box;
  border-radius: 3px;
  padding: 16px;
  width: inherit;
  position: relative;
  resize: vertical;
  line-height: 16.71px;
  font-size: 14px;
}
input.with-error,
textarea.with-error,
select.with-error {
  border: 1px solid var(--error-color);
  animation: shake .1s linear 3;
}
@keyframes shake {
  0% { left: -5px; }
  100% { left: 5px; }
}
input.with-error::placeholder,
select.with-error::placeholder,
textarea.with-error::placeholder {
  color: var(--error-color);
  opacity: .5;
}
input::placeholder,
option:disabled,
textarea::placeholder {
  font-weight: 400;
  font-size: 14px;
  line-height: 16.71px;
  color: #16193180;
}
.input__eye {
  position: absolute;
  right: 20px;
  top: 14px;
  display: inline-block;
  cursor: pointer;
  overflow: hidden;
}
.reset {
  position: absolute;
  right: 15px;
  top: 18px;
  cursor: pointer;
  transition: all .2s ease;
}
.reset:hover {
  fill: var(--error-color);
}
input:focus,
select:focus,
textarea:focus {
  border: 1px solid var(--accent-color);
}
input:focus .placeholder {
  position: relative;
}
input.with-error:focus,
select.with-error:focus,
textarea.with-error:focus {
  border: 1px solid var(--error-color);
}

.disabled-input {
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  background: rgba(22, 25, 49, 0.05);
  mix-blend-mode: normal;
  border-radius: 3px;
  padding: 16px 18px 17px 18px;
}
.disabled-input span {
  font-weight: 400;
  font-size: 16px;
  line-height: 17px;
  letter-spacing: 0.02em;
  color: var(--primary-color);
}

.v-enter-active,
.v-leave-active {
  transition: all .2s ease;
}
.v-enter-from {
  transform: translateY(-100%);
}
.v-leave-to {
  transform: translateY(100%);
}
.error-enter-active,
.error-leave-active {
  transition: all .2s ease;
}
.error-enter-from,
.error-leave-to {
  opacity: 0;
  transform: translateY(-50%);
}
</style>
