<template>
  <router-link
    v-if="course.lessons_finished < course.lessons.length && course.status === 'published'"
    :to="`/lesson/${course.id}/unit/${firstUnfinishedLesson(course.lessons).id}`"
    class="continue"
    :title="`${firstUnfinishedLesson(course.lessons).order}. ${firstUnfinishedLesson(course.lessons).name}`"
  >
    <SvgIcon name="play" :viewboxWidth="16" :viewboxHeight="16" :width="16" :height="16" />
    <span>Продолжить: {{ firstUnfinishedLesson(course.lessons).order }}. {{ firstUnfinishedLesson(course.lessons).name }}</span>
  </router-link>
</template>

<script setup>
defineProps({
  course: {
    type: Object,
    default: null
  }
})
const firstUnfinishedLesson = (lessons) => {
  for (const lesson of lessons) {
    if (!lesson.finished_at) return lesson
  }
}
</script>

<style scoped lang="scss">
.continue {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  transition: all .2s ease;
  overflow: hidden;

  &.list-type{
    display: none;
  }

  &:hover {
    & svg {
      fill: #0082FA;
    }
    & span {
      color: #0082FA;
    }
  }

  & svg {
    min-width: 16px;
    transition: all .2s ease;
  }
  & span {
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.02em;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    transition: all .2s ease;
  }
}
/* SKELETON */
.disabled .content {
  background-color: #fff !important;
  box-shadow: 0 4px 10px rgb(0 0 0 / 10%);
}
</style>
