<template>
<div class="categories">
  <button
    v-if="selectCategory"
    class="toggle active"
  >
    {{ selectCategory.name }}
  </button>

  <button
    class="toggle"
    :class="{ active: !selectCategory }"
    @click="() => { onSearch(null) }"
  >
    Все направления
  </button>

  <button
    class="toggle"
    :class="{ hide: route.query.course_category_id === String(catregory.id) }"
    v-for="catregory of categoriesForToggles"
    :key="catregory"
    @click="() => { onSearch(catregory.id, true) }"
  >
    {{ catregory.name }}
  </button>
  <button
    class="toggle more"
    :class="{ active: showDrop }"
    @click="() => { showDrop = !showDrop }"
    v-click-outside="() => { showDrop = false }"
  >
    <SvgIcon name="more"/>

    <Transition>
      <div class="options" v-if="showDrop">
        <button
          class="option"
          :class="{ active: route.query.course_category_id === String(catregory.id) }"
          v-for="catregory of categories"
          :key="catregory"
          @click="() => { onSearch(catregory.id) }"
        >
          {{ catregory.name }}
        </button>
      </div>
    </Transition>
  </button>
</div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
import { getCourseCategories } from '@/actions/uiBusiness/courses'
import { useRoute, useRouter } from 'vue-router'

const route = useRoute()
const router = useRouter()

const categories = ref([])
const categoriesForToggles = ref([])
const selectCategory = computed(() => {
  if (route.query.course_category_name) return { id: Number(route.query.course_category_id), name: route.query.course_category_name }
  if (categories.value.length === 0) return null
  return categories.value.find(category => category.id === Number(route.query.course_category_id))
})

const showDrop = ref(false)

onMounted(async () => {
  const response = (await getCourseCategories()).data
  categories.value = response.slice().sort()
  categoriesForToggles.value = response.splice(0, 3)
})

const onSearch = (category_id) => {
  router.push({ query: { course_category_id: category_id } })
}
</script>

<style scoped lang="scss">
.categories {
  display: flex;
  align-items: center;
  grid-gap: 5px;
}
.toggle {
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 9px;
  position: relative;

  font-weight: 500;
  font-size: 13px;
  color: rgba(22, 25, 49, 0.45);

  background: #FFFFFF;
  border-radius: 3px;
  transition: all .2s ease;

  & svg {
    fill: #9697A2;
    transition: 2s ease;
  }

  &:hover {
    color: rgba(22, 25, 49, 0.8);
    & svg {
      fill: rgba(22, 25, 49, 0.8);
    }
  }

  &.active {
    color: rgba(22, 25, 49, 0.8);
    background: rgba(22, 25, 49, 0.1);
    & svg {
      transform: scale(1.2);
      fill: rgba(22, 25, 49, 0.8);
    }
  }

  &.hide {
    display: none;
  }
}

.options {
  width: 250px;
  height: 250px;
  overflow-y: scroll;

  position: absolute;
  right: 0;
  top: calc(100% + 2px);
  z-index: 3;

  padding: 3px 0;

  background: #FFFFFF;
  box-shadow: 0px 2px 11px rgba(0, 0, 0, 0.1);
  border-radius: 3px;

  & .option {
    width: 100%;
    padding: 14px 16px;
    background: #FFFFFF;

    font-weight: 500;
    font-size: 16px;
    color: #000000;
    text-align: left;

    transition: all .2s ease;

    &:hover {
      background: #EBECEE;
    }

    &.active {
      background-color: rgba(#0082FA, .2) !important;
    }
  }
}
</style>
