<template>
  <div class="wrapper-system">
    <button class="back" @click="back">
      <SvgIcon
        name="back"
        :viewboxHeight="18"
        :viewboxWidth="18"
        :height="18"
        :width="18"
      />
      <span>Уроки</span>
    </button>
    <h1>
      <span class="title" v-if="lessonDatas.name || isNewLessonPage">
        {{ lessonDatas.name }}
      </span>
      <span class="title skeleton long-text" v-else></span>
      <div class="buttons" v-if="lessonDatas.name || isNewLessonPage">
        <div v-if="!isNewLessonPage">
          <Button
            :name="
              haveHomework
                ? 'Редактировать домашнее задание'
                : 'Создать домашнее задание'
            "
            type="dark"
            @click="haveHomework ? editHomework() : newHomework()"
            shadowed
            :icon="{
              name: 'homework',
              position: 'left',
              width: 16,
              height: 16,
              fill: 'white'
            }"
          />
        </div>
        <Button
          @click="sendLessonData"
          :disabled="handleError(hasError)"
          :loading="loading"
          name="сохранить"
          shadowed
        />
      </div>
      <div class="buttons disabled" v-else>
        <Button
          name="Редактировать домашнее задание"
          type="dark"
          class="skeleton"
        >
          <template #left>
            <SvgIcon
              name=""
              :viewboxHeight="16"
              :viewboxWidth="24"
              :width="24"
              :height="16"
              style="fill: white"
            />
          </template>
        </Button>
        <Button name="Создать домашнее задание" class="skeleton" />
      </div>
    </h1>
    <div class="edit">
      <div class="left">
        <span class="title">Детали</span>
        <div class="group">
          <span class="name">Название урока</span>
          <div>
            <Input
              class="group--input"
              v-model="lessonDatas.name"
              placeholder="Введите название урока"
              staticPlaceholder
              required
              @error="hasError.name = true"
              @success="hasError.name = false"
            />
            <p class="description">
              Выберите легко читаемый и запоминающийся заголовок для вашего
              продукта. Пример: «Present Simple» или «Портретная съемка». Не
              более 140 символов.
            </p>
          </div>
        </div>
        <div class="group">
          <span class="name">Краткое описание урока</span>
          <div>
            <Input
              class="group--input"
              v-model="lessonDatas.description"
              placeholder="Введите краткое описание урока"
              staticPlaceholder
              required
              @error="hasError.description = true"
              @success="hasError.description = false"
            />
            <p class="description">
              Краткое описание урока, состоящее из одного предложения. Это будет
              отображаться на посадочной странице курса.
            </p>
          </div>
        </div>
        <span class="title">Медиа</span>
        <UploadVideo v-model="lessonDatas.video" />
        <Input
          type="editor"
          v-model="lessonDatas.content"
          staticPlaceholder
          required
          placeholder="Введите контент урока"
          @error="hasError.content = true"
          @success="hasError.content = false"
        />
      </div>
      <div class="right">
        <section class="uploads">
          <span class="title">Загрузки</span>
          <div
            class="uploads"
            v-if="
              lessonDatas.lesson_files.length === 0 &&
              newLessonFiles.length === 0
            "
          >
            <span>Добавьте файлы урока</span>
          </div>
          <div v-else>
            <div
              class="uploads"
              v-for="file of lessonDatas.lesson_files"
              :key="file"
            >
              <div class="file">
                <img :src="getFileUrl(file)" alt="" v-if="isImage(file.name)" />
                <span>{{ file.name }}</span>
              </div>
              <div class="actions">
                <a :href="getFileUrl(file)" download target="_blank">
                  <SvgIcon name="download" />
                </a>
                <SvgIcon
                  class="x"
                  name="x"
                  :viewboxHeight="14"
                  :viewboxWidth="21"
                  :width="24"
                  :height="24"
                  @click="deleteFile(file.id)"
                />
              </div>
            </div>
            <div
              class="uploads"
              v-for="(file, key) of newLessonFiles"
              :key="file"
            >
              <div class="file">
                <img
                  :src="renderedImages[file.name]"
                  alt=""
                  v-if="isImage(file.name)"
                />
                <span>{{ file.name }}</span>
              </div>
              <div class="actions">
                <SvgIcon
                  class="x"
                  name="x"
                  :viewboxHeight="14"
                  :viewboxWidth="21"
                  :width="24"
                  :height="24"
                  @click="deleteNewFile(key)"
                />
              </div>
            </div>
          </div>
          <button class="add-file">
            <SvgIcon
              name="plus"
              :viewboxHeight="14"
              :viewboxWidth="14"
              :width="14"
              :height="14"
              style="fill: #0082fa"
            />
            <input
              type="file"
              id="upload-lesson-files"
              multiple
              style="display: none"
              @change="uploadFiles"
            />
            <label for="upload-lesson-files">Добавить файлы</label>
          </button>
          <span class="tip"
            >Вы можете добавить файлы размером не более 10мб. Если вы хотите
            прикрепить видео более 10мб, вы можете загрузить его на видеохостинг
            youtube по закрытой ссылке и прикрепить ссылку к вашему уроку.</span
          >
        </section>
        <div class="statuses">
          <div class="title">Статус</div>
          <div class="group">
            <input
              type="radio"
              name="status"
              id="draft"
              value="draft"
              v-model="lessonDatas.status"
            />
            <label
              for="draft"
              data-tooltip="Соберите урок, сохраните его как черновик и опубликуйте в удобное для вас время. При сохранении урока в виде черновика, видите его только вы. "
            >
              <div class="status draft">
                <SvgIcon
                  name="check"
                  :viewboxHeight="18"
                  :viewboxWidth="18"
                  :height="18"
                  :width="18"
                />
                <span class="draft">Черновик</span>
              </div>
            </label>
          </div>
          <div class="group">
            <input
              type="radio"
              name="status"
              id="published"
              value="published"
              v-model="lessonDatas.status"
            />
            <label
              for="published"
              data-tooltip="Сохраните урок в статусе “Опубликовано” и его сразу смогут увидеть все ваши ученики. "
            >
              <div class="status published">
                <SvgIcon
                  name="check"
                  :viewboxHeight="18"
                  :viewboxWidth="18"
                  :height="18"
                  :width="18"
                />
                <span class="published">Опубликовано</span>
              </div>
            </label>
          </div>
        </div>
        <button
          class="delete-lesson"
          @click="showDeleteModal"
          v-if="!isNewLessonPage"
        >
          <SvgIcon
            name="trash"
            :viewboxHeight="18"
            :viewboxWidth="18"
            :height="18"
            :width="18"
          />
          <span>Удалить урок</span>
        </button>
      </div>
    </div>
    <transition name="modal">
      <Modal v-if="deleteModalIsActive" :hide="hideDeleteModal">
        <div class="modal">
          <span class="title"
            >Вы действительно хотите удалить домашнее задание?</span
          >
          <div class="buttons">
            <Button name="нет" type="cancel" @click="hideDeleteModal" />
            <Button name="да" @click="onDeleteLesson" />
          </div>
        </div>
      </Modal>
    </transition>
  </div>
</template>

<script setup>
import { computed, reactive, ref } from 'vue'

import Modal from '@/components/common/Modal.vue'

import { useRoute, useRouter } from 'vue-router'

import { useToast } from '@/modules/toast'
import { onMounted } from 'vue-demi'

import {
  getLesson,
  createNewLesson,
  deleteLesson,
  editLessonData
} from '@/actions/uiBusiness/lessons'
import getFileUrl from '@/helpers/getFileUrlByDate'
import UploadVideo from '@/components/uiBusiness/Courses/UploadVideo.vue'

const deleteModalIsActive = ref(false)
const router = useRouter()
const route = useRoute()
const { toast } = useToast()
const lessonDatas = ref({
  name: null,
  description: null,
  video: null,
  content: null,
  course_id: Number(route.params.course_id),
  status: 'draft',
  lesson_files: []
})
const newLessonFiles = ref([])
const filesToDetach = ref([])
const homeworkId = ref(null)
const haveHomework = ref(false)
const renderedImages = ref([])
const loading = ref(false)
const hasError = reactive({
  name: true,
  description: true,
  content: true
})
const handleError = (fields) => {
  for (const field in fields) {
    if (fields[field]) return true
  }
  return false
}

const isNewLessonPage = computed(() => route.params.lesson_id === 'new')

onMounted(async () => {
  if (!isNewLessonPage.value) {
    const response = (await getLesson(route.params.lesson_id)).data
    lessonDatas.value = response
    haveHomework.value = response.homework.length > 0
    if (haveHomework.value) homeworkId.value = response.homework[0].id
  }
})

const showDeleteModal = () => {
  deleteModalIsActive.value = true
}
const hideDeleteModal = () => {
  deleteModalIsActive.value = false
}

// const goPreview = () => {
//   router.push({
//     name: 'LessonPreview',
//     params: {
//       preview: 'text'
//     }
//   })
// }

const back = () => {
  router.go(-1)
}

const sendLessonData = async () => {
  loading.value = true
  try {
    if (isNewLessonPage.value) {
      await createNewLesson(
        route.params.course_id,
        lessonDatas.value,
        newLessonFiles.value
      )
    } else {
      await editLessonData(
        route.params.lesson_id,
        lessonDatas.value,
        newLessonFiles.value,
        filesToDetach.value
      )
    }
  } finally {
    loading.value = false
  }
  back()
}

const editHomework = () => {
  router.push(
    `/business/courses/${route.params.course_id}/lessons/${route.params.lesson_id}/homework/${homeworkId.value}`
  )
}
const newHomework = () => {
  router.push(
    `/business/courses/${route.params.course_id}/lessons/${route.params.lesson_id}/homework/new`
  )
}

const onDeleteLesson = async () => {
  hideDeleteModal()
  try {
    await deleteLesson(route.params.lesson_id)
    toast({
      message: 'Урок удален'
    })
    back()
  } catch (error) {
    console.error(error)
  }
}

const deleteFile = (fileID) => {
  filesToDetach.value.push(fileID)
  lessonDatas.value.lesson_files = lessonDatas.value.lesson_files.filter(
    (file) => file.id !== fileID
  )
}
const deleteNewFile = (file) => {
  newLessonFiles.value = [...newLessonFiles.value].filter(
    (item, index) => index !== file
  )
}

const uploadFiles = (event) => {
  if (event.target.files.length > 0) {
    newLessonFiles.value = [...newLessonFiles.value, ...event.target.files]
    renderedImages.value = []
    for (const file of newLessonFiles.value) {
      if (isImage(file.name)) renderImg(file)
    }
  }
}

const isImage = (fileName) => {
  const type = fileName.slice(fileName.length - 4)
  const imageTypes = ['.png', '.jpg', 'jpeg', '.gif', '.JPG']
  return imageTypes.includes(type)
}

const renderImg = async (file) => {
  const reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = (event) => {
    renderedImages.value[file.name] = event.target.result
  }
}
</script>

<style scoped>
.back {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
  padding-left: 20px;
  transition: all 0.3s ease;
}
.back:hover {
  gap: 15px;
}
.back span {
  color: rgba(22, 25, 49, 0.5);
  font-weight: 500;
  font-size: 16px;
}
h1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
h1 .title {
  font-weight: bold;
  font-size: 24px;
  color: #161931;
  padding-left: 20px;
}
h1 .buttons {
  display: flex;
  gap: 10px;
}

p.description {
  font-weight: 400;
  font-size: 12px;
  color: #161931;
}
.edit {
  display: grid;
  grid-template-columns: 60% 40%;
  grid-gap: 20px;
  width: calc(100% - 20px);
}
.edit .left {
  background: #ffffff;
  border: 1px solid rgba(22, 25, 49, 0.1);
  box-sizing: border-box;
  box-shadow: 0px 0px 10px rgba(22, 25, 49, 0.1);
  border-radius: 5px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding-bottom: 40px;
}
.edit .left .title {
  font-weight: 500;
  font-size: 20px;
  color: #161931;
}
.edit .left .group {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.edit .left .group .group--input {
  margin-bottom: 8px;
}
.edit .left input {
  border: 1px solid rgba(22, 25, 49, 0.3);
  box-sizing: border-box;
  border-radius: 3px;
  padding: 16px;
}
.edit .left input:focus {
  border: 1px solid var(--accent-color);
}
.edit .left .upload {
  display: flex;
  gap: 20px;
}
.edit .left .upload button {
  padding: 13px 30px;
  border: 1px solid rgba(22, 25, 49, 0.3);
  box-sizing: border-box;
  border-radius: 3px;
  display: flex;
  align-items: center;
  gap: 10px;
  transition: all 0.3s ease;
}
.edit .left .upload button svg {
  transition: all 0.3s ease;
  fill: black;
}
.edit .left .upload button span {
  transition: all 0.3s ease;
  font-size: 16px;
}
.edit .left .upload button:hover {
  border: 1px solid #0082fa;
}
.edit .left .upload button:hover svg {
  fill: #0082fa;
}
.edit .left .upload button:hover span {
  color: #0082fa;
}
.edit .right {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.edit .right .avatar {
  background: #ffffff;
  border: 1px solid rgba(22, 25, 49, 0.1);
  box-sizing: border-box;
  box-shadow: 0px 0px 10px rgba(22, 25, 49, 0.1);
  border-radius: 5px;
  padding: 20px;
}
.edit .right .avatar .title {
  font-weight: 500;
  font-size: 20px;
  color: #161931;
  margin-bottom: 20px;
}
.edit .right .avatar .image {
  border: 1px solid rgba(22, 25, 49, 0.3);
  box-sizing: border-box;
  border-radius: 3px;
  display: flex;
  align-content: center;
  justify-content: center;
  overflow: hidden;
  margin-bottom: 10px;
  width: 100%;
  overflow: hidden;
  margin: 0;
  padding-top: 56.25%;
  position: relative;
}
.edit .right .avatar .image img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
}
.edit .right .avatar .image .default-img {
  height: 150px;
}
.edit .right .avatar p {
  font-weight: 400;
  font-size: 12px;
  color: #161931;
  margin-bottom: 10px;
}
.edit .right .avatar .buttons {
  display: flex;
  align-items: center;
  gap: 20px;
}
.edit .right .avatar .buttons button {
  font-weight: 500;
  font-size: 14px;
  color: #ff5656;
}
.tip {
  padding-top: 10px;
  color: rgba(22, 25, 49, 0.8);
  font-size: 12px;
}
.edit .right .statuses {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border: 1px solid rgba(22, 25, 49, 0.1);
  box-sizing: border-box;
  box-shadow: 0px 0px 10px rgba(22, 25, 49, 0.1);
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 25px;
}
.edit .right .statuses .title {
  font-weight: 500;
  font-size: 20px;
  color: #161931;
  margin-bottom: 20px;
}
.edit .right .statuses .group {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}
.edit .right .statuses .status {
  border-radius: 20px;
  padding: 6px 10px;
  font-weight: 500;
  font-size: 14px;
  height: fit-content;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
}
.edit .right .status.published {
  background: rgba(0, 219, 127, 0.15);
}
.edit .right .status.published svg {
  fill: #00854d;
}
.edit .right .status.published span {
  color: #00854d;
}
.edit .right .status.draft {
  background: rgba(22, 25, 49, 0.15);
}
.edit .right .status.draft svg {
  fill: rgba(22, 25, 49, 0.8);
}
.edit .right .status.draft span {
  color: rgba(22, 25, 49, 0.8);
}
.edit .delete-lesson {
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 10px;
}
.edit .delete-lesson span {
  color: #ff5656;
}
.edit .delete-lesson svg {
  fill: #ff5656;
}

.upload-file {
  display: none;
}
.delete-image {
  padding: 5px 10px;
  border-radius: 3px;
}

.modal {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}
.modal .title {
  font-weight: bold;
  font-size: 24px;
  color: #161931;
}
.modal .buttons {
  display: flex;
  gap: 20px;
}

.modal-enter-active,
.modal-leave-active {
  transition: all 0.3s ease;
}
.modal-enter-from,
.modal-leave-to {
  opacity: 0;
}

.disabled {
  pointer-events: none;
}
section.uploads {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border: 1px solid rgba(22, 25, 49, 0.1);
  box-sizing: border-box;
  box-shadow: 0px 0px 10px rgba(22, 25, 49, 0.1);
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 25px;
}
section.uploads .title {
  font-weight: 500;
  font-size: 20px;
  color: #161931;
  margin-bottom: 10px;
}
section.uploads .uploads {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 20px;
  border: 1px solid rgba(22, 25, 49, 0.3);
  box-sizing: border-box;
  border-radius: 3px;
  margin-bottom: 10px;
}
section.uploads .uploads .file {
  display: flex;
  align-items: center;
  gap: 5px;
  max-width: 60%;
}
section.uploads .uploads .file img {
  height: 24px;
  width: 24px;
}
section.uploads .uploads span {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  line-height: 1em;
  height: 1em;
  white-space: pre;
}
section.uploads .actions {
  display: flex;
  gap: 15px;
}
section.uploads .actions svg {
  cursor: pointer;
  transition: all 0.3s ease;
}
section.uploads .actions svg:hover {
  fill: #0082fa;
}
section.uploads .actions .x:hover {
  fill: #ff5656;
}
.uploads .add-file {
  width: fit-content;
  display: flex;
  gap: 7px;
  align-items: center;
}
.uploads .add-file label {
  transition: all 0.4s ease;
  color: #0082fa;
  cursor: pointer;
}
.uploads .add-file:hover label {
  transform: translateX(5px);
}
.uploads .add-file svg {
  fill: #0082fa;
  transition: all 0.4s ease;
}
.uploads .add-file:hover svg {
  transform: rotate(90deg);
}
</style>
