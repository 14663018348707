<template>
  <div>
    <div class="sidebar">
      <div class="logo-details">
        <router-link to="/learning" class="logo-back">
          <SvgIcon class="back" name="chevron-left" :viewboxWidth="24" :viewboxHeight="40" :width="20" :height="20" />
          <span class="logo-name">A-Class Online</span>
        </router-link>
      </div>
      <div class="sidebar-header">
        <router-link :to="`/course/${data.course_data.id}`" v-if="data.course_data.name">{{ data.course_data.name }}</router-link>
        <span class="skeleton dark middle-text" v-else></span>
        <span class="progress"  v-if="data.course_data.name">Прогресс по курсу: {{ data.finished_lessons_count() }}/{{ data.lessons_data.length }}</span>
        <span class="progress skeleton dark middle-text" v-else></span>
      </div>
      <nav class="sidebar-body">
        <div class="lessons" v-if="data.lessons_data.length > 0">
          <div
            class="lesson" v-for="(value, index) of data.lessons_data" :key="value"
          >
            <router-link
              class="lesson--inner"
              :class="{ disabled: index > data.finished_lessons_count() && !fullAccess }"
              :to="`/lesson/${route.params.course_id}/unit/${value.id}`"
              :title="value.name"
              >
              {{ value.name }}
              <div class="completed" v-if="value.finished_at"></div>
              <div class="not completed" v-else></div>
            </router-link>

            <router-link
              class="homework"
              :to="'/lesson/' + route.params.course_id + '/homework/' + homework.id"
              :class="{ disabled: index > data.finished_lessons_count() && !fullAccess }"
              v-for="homework of value.homework"
              :key="homework"
              :title="homework.name"
            >
              {{homework.name}}
              <div class="completed" v-if="value.finished_at"></div>
              <div class="not completed" v-else></div>
            </router-link>

          </div>
        </div>
        <div class="lessons disabled" v-else>
          <div class="lesson" v-for="value of 15" :key="value">
            <a class="lesson--inner">
              <span class="skeleton dark middle-text"></span>
            </a>
          </div>
        </div>
      </nav>
    </div>
    <div class="content">
      <Header />
      <slot />
    </div>
  </div>
</template>

<script setup>
import Header from './Header.vue'

import { useAuth } from '@/modules/auth'
import { useRoute } from 'vue-router'

import checkFullAccess from '@/helpers/checkFullAccess.js'

defineProps({
  data: {
    type: Object,
    default: () => {}
  }
})
const route = useRoute()
const { user } = useAuth()

const fullAccess = checkFullAccess(route.params.course_id, user.value.data)
</script>

<style scoped>
a.disabled {
  pointer-events: none;
}
a.router-link-active .link-name {
  color: #0082FA!important;
  opacity: 1;
}

a.router-link-active svg {
  fill: #0082FA!important;
}
.sidebar.close .nav-links .router-link-active {
  background: #06050c;
  transition: all .2s ease;
}
.sidebar.close .nav-links .sub-menu.blank .router-link-active {
  background: none;
}
.content {
  position: relative;
  top: 70px;
  left: 260px;
  transition: all .5s ease;
  max-width: calc( 100% - 260px);
  box-sizing: border-box;
}
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 260px;
  background: var(--primary-color);
  z-index: 100;
  transition: all 0.5s ease;
}
.sidebar.close {
  width: 78px;
}
.sidebar.close ~ .content {
  left: 78px;
  transition: all .5s ease;
  max-width: calc( 100% - 78px);
  position: relative;
}
.sidebar.close .nav-links{
  overflow: visible;
}
.sidebar.close .logo-name {
  display: none;
}
.sidebar .logo-details {
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 26px;
}
.sidebar .logo-details svg {
  fill: #fff;
  cursor: pointer;
}
.sidebar .logo-details .logo-back {
  display: flex;
  cursor: pointer;
  align-items: center;
  position: relative;
}
.sidebar .logo-details .logo-back:hover .back {
  opacity: 1;
  pointer-events: all;
}
.sidebar .logo-details .logo-back:hover .logo-name {
  transform: translateX(30px);
}
.sidebar .logo-details .logo-back .back {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  transition: all .2s ease;
}
.sidebar .logo-details .logo-name {
  font-size: 24px;
  font-weight: 500;
  color: #fff;
  border-bottom: 2px solid #fff;
  white-space: pre;
  transition: all .2s ease;
}
.sidebar .nav-links {
  height: 100%;
  padding-top: 30px;
  overflow-x: hidden;
}
.sidebar .nav-links::-webkit-scrollbar{
  display: none;
}
.sidebar .nav-links li {
  position: relative;
  list-style: none;
  transition: all .4s ease;
}
.sidebar .nav-links li:hover {
  background: #06050c;
}
.sidebar .nav-links li .link-dropdown {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sidebar .nav-links li a {
  display: flex;
  align-items: center;
}
.sidebar .nav-links li svg {
  min-width: 78px;
  fill: #fff;
  cursor: pointer;
  transition: all 0.3s ease;
}
.sidebar .nav-links li svg.arrow {
  min-width: 0;
  margin-right: 26px;
}
.sidebar .nav-links li.show-menu svg.arrow {
  transform: rotate(-180deg);
}
.sidebar .nav-links li .arrow {
  min-width: 0;
  margin-right: 26px;
}
.sidebar.close .nav-links li svg.arrow {
  opacity: 0;
  pointer-events: none;
}
.sidebar .nav-links li a .link-name {
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  height: 70px;
  line-height: 70px;
  text-align: center;
  transition: all 0.4s ease;
  white-space: nowrap;
}
.sidebar .nav-links li .sub-menu a.router-link-active {
  opacity: 1;
}
.sidebar.close .nav-links li a .link-name {
  opacity: 0;
  pointer-events: none;
}
.sidebar.close .nav-links li .link-dropdown a {
  width: 78px;
}
.sidebar .nav-links li .sub-menu {
  padding: 6px 6px 14px 80px;
  margin-top: -10px;
  background: #1d1b31;
  display: none;
}
.sidebar .nav-links li.show-menu .sub-menu {
 display: block;
}
.sidebar .nav-links li .sub-menu a{
  color: #fff;
  font-size: 15px;
  padding: 5px 0;
  white-space: nowrap;
  opacity: 0.6;
  transition: all 0.3s ease;
}
.sidebar.close .nav-links li .sub-menu{
  position: absolute;
  left: 100%;
  top: -10px;
  margin-top: 0;
  padding: 10px 20px;
  border-radius: 0 6px 6px 0;
  opacity: 0;
  display: block;
  pointer-events: none;
  transition: 0s;
}
.sidebar.close .nav-links li:hover .sub-menu {
  top: 0;
  opacity: 1;
  pointer-events: auto;
  transition: all 0.4s ease;
}
.sidebar .nav-links li .sub-menu a:hover{
  opacity: 1;
}
.sidebar.close .nav-links li .sub-menu .link-name { /* Чет не работатает */
  font-size: 18px;
  opacity: 1;
  display: block;
}
.sidebar .nav-links li .sub-menu .link-name {
  display: none;
}
.sidebar .nav-links li .sub-menu.blank {
  opacity: 0;
  pointer-events: none;
  padding: 3px 20px 6px 16px;
}
.sidebar .nav-links li:hover .sub-menu.blank {
  top: 50%;
  transform: translateY(-50%);
}
.sidebar .nav-links li .sub-menu .link_name {
  display: none;
}
.sidebar-header {
  padding: 1.5em 1em;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.sidebar-header a {
  font-size: 16px;
  font-weight: 700;
  color: #fff;
}
.sidebar-header a:hover {
  text-decoration: underline;
}
.sidebar-header span {
  color: #fff;
  font-size: 14px;
  line-height: 16px;
}

nav {
  height: 100%;
  width: inherit;
  overflow-y: auto;
}
nav::-webkit-scrollbar {
    width: 10px;
}
nav::-webkit-scrollbar-track {
    background: #161931;
    box-shadow: 0 0 2px rgba(0, 0, 0, .2) inset;
}
nav::-webkit-scrollbar-thumb {
    background: #37373A;
}
nav::-webkit-scrollbar-thumb:hover {
    background: #5221E6;
}
.lessons {
  display: grid;
  width: inherit;
  padding-bottom: 160px;
}
.lessons.disabled {
  pointer-events: none;
  overflow: hidden;
}
.lessons .lesson {
  /* overflow: hidden; */
  text-overflow: ellipsis;
  width: inherit;
}
.lessons .lesson .lesson--inner,
.lessons .lesson .homework {
  color: #fff;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
  padding: 1em 1em 1em 2em;
  display: block;
  width: inherit;
  text-align: start;
  font-size: 1em;
  position: relative;
}
.lessons .lesson .homework {
  padding: 1em 1em 1em 3em;
  font-size: .8em;
}
.lessons .lesson button:hover,
.lessons .lesson a:hover {
  background: #0081fa10;
}
.lessons .lesson button.active,
.lessons .lesson a.router-link-active {
  background: #0081fa50;
}
.lessons .lesson button .completed,
.lessons .lesson a .completed {
  position: absolute;
  height: 100%;
  width: 4px;
  background: var(--accent-color);
  left: 0;
  top: 0;
}
.lessons .lesson button .not.completed,
.lessons .lesson a .not.completed {
  background: #2e2e31;
}
</style>
