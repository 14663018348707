<template>
<main class="landing-page">
  <Header/>
  <Intro
    :school="school"
    :coursesCount="courses ? courses.length : 0"
    :schoolAdmin="schoolAdmin"
    :categories="categories"
    @showEditModal="() => { showModal.editSchool = true }"
  />

  <section class="wrapper wrapper-landing" v-if="courses">
    <div class="landing-header">
      <h2 class="landing-title">Курсы этой школы</h2>
      <router-link v-if="courses.length > 10" class="landing-link" :to="`/courses?school_id=${school.id}&school_name=${school.name}`">
        {{ courses.length ?? 'Все' }}
      </router-link>
    </div>
    <Slider
      v-if="courses.length"
      :link="
        courses.length > 10 ?
        `/courses?school_id=${school.id}&school_name=${school.name}`
        : null
      "
    >
      <CourseCard
        v-for="course of courses"
        :key="course"
        :course="course"
      />
    </Slider>

    <Slider v-else>
      <CourseCardSkeleton class="course-card" v-for="course in 10" :key="course" />
    </Slider>
  </section>

  <section class="wrapper wrapper-landing" v-if="school.teachers.length">
    <div class="landing-header">
      <h2 class="landing-title">Преподователи</h2>
      <router-link
        v-if="school.teachers.length > 10"
        class="landing-link"
        :to="`/school/${school.id}/teachers`"
      >
        {{ school.teachers.length ?? 'Все' }}
      </router-link>
    </div>
    <Slider :link="school.teachers.length > 10 ? `/courses?school_id=${school.id}` : null">
      <TeacherCard
          v-for="teacher of school.teachers"
          :key="teacher"
          :teacher="teacher"
        />
    </Slider>
  </section>

  <section class="wrapper wrapper-landing gallery" v-if="school.school_photos.length">
    <div class="landing-header">
      <div class="left">
        <h2 class="landing-title">Фотографии</h2>
        <button v-if="schoolAdmin" class="addPhotoBtn" @click="() => { showModal.createImage = true }">
          <span>Добавить фотографию</span>
          <SvgIcon name="plus" :viewboxHeight="14" :viewboxWidth="14" :width="14" :height="14" fill="#0082FA"/>
        </button>
      </div>

      <router-link
        class="landing-link"
        :to="`/school/${school.id}/teachers`"
      >
      {{ school.school_photos.length ?? 'Все' }}
      </router-link>
    </div>
    <Slider>
      <PhotoCard
        v-for="img of school.school_photos"
        :key="img.id"
        :photo="img"
        :schoolData="school"
        :schoolAdmin="schoolAdmin"
        @finish="getData"
      />
    </Slider>
  </section>

  <!-- <div class="gallery" v-if="schoolAdmin">
    <div class="wrapper">
      <h2>Галерея</h2>
      <div class="slider" v-if="school.school_photos">
        <div class="upload-image" v-if="schoolAdmin">
          <input type="file" class="upload-images" @change="uploadImagesRun" multiple accept="image/png, image/gif, image/jpeg, image/svg" style="display: none;">
          <Svg class="plus" name="plus" :viewboxHeight="14" :viewboxWidth="14" :width="84" :height="84" fill="#161931" @click="uploadImages"/>
        </div>
        <div class="images" v-for="photo in school.school_photos" :key="photo">
          <ImageLoader v-if="photo?.link" :src="photo.link"/>
          <img v-else :src="photo" alt="">
          <button class="delete-gallery-item" v-wave @click="deletePhoto(photo.id)">
            <Svg v-if="schoolAdmin" name="x" :viewboxHeight="14" :viewboxWidth="14" :height="14" :width="14" fill="#FF5656"/>
          </button>
        </div>
      </div>
      <div class="slider" v-else>
        <div class="images" v-for="photo in 10" :key="photo">
          <div class="skeleton img"></div>
        </div>
      </div>
    </div>
  </div> -->

  <section class="wrapper wrapper-landing">
    <h2 class="landing-title">Статистика и отзывы пользователей</h2>
    <div class="ratings-block">
      <Statistics
        :studentsGraduated="school.students_graduated_count"
        :studentsCount="school.students_count"
        :coursesCount="courses ? courses.length : 0"
      />
      <!-- <Ratings /> -->
    </div>
  </section>

  <!-- <section class="wrapper wrapper-landing">
    <div class="landing-header">
      <h2 class="landing-title">Отзывы выпускников</h2>
      <router-link class="landing-link" :to="`/school/${school.id}/teachers`">Все</router-link>
    </div>
    <Slider>
      <Review />
      <Review />
      <Review />
    </Slider>
  </section> -->

  <transition mode="out-in">
    <EditSchoolModal
      v-if="showModal.editSchool"
      :data="school"
      @hide="() => { showModal.editSchool = false }"
      @finish="getData"
    />
    <CreatePhotoModal
      v-else-if="showModal.createImage"
      :data="school"
      @hide="() => { showModal.createImage = false }"
      @finish="getData"
    />
  </transition>

  <Footer/>
</main>
</template>

<script setup>
import Header from '@/components/uiLanding/layouts/Header.vue'
import Footer from '@/components/uiLanding/layouts/Footer.vue'

import Intro from '@/components/uiLanding/Schools/Page/Intro/Intro.vue'
import TeacherCard from '@/components/uiLanding/Schools/Page/TeacherCard.vue'
import CourseCard from '@/components/uiLanding/Courses/List/CourseCard.vue'
import PhotoCard from '@/components/uiLanding/Schools/Page/Photos/PhotoCard.vue'
import CourseCardSkeleton from '@/components/uiLanding/Courses/List/CourseCardSkeleton.vue'
import EditSchoolModal from '@/components/uiLanding/Schools/Page/EditSchoolModal.vue'
import CreatePhotoModal from '@/components/uiLanding/Schools/Page/Photos/CreatePhotoModal.vue'

import Statistics from '@/components/uiLanding/Ratings/Statistics.vue'
import Slider from '@/components/common/Slider/Slider.vue'
// import Review from '@/components/uiLanding/Reviews/Review.vue'
// import Ratings from '@/components/uiLanding/Ratings/Ratings.vue'

import { computed, onMounted, reactive, ref } from 'vue-demi'
import { useRoute } from 'vue-router'

import { useAuth } from '@/modules/auth'
import { getSchoolData, schoolHaveCourses } from '@/actions/uiLanding/school'

const route = useRoute()
const { user } = useAuth()

const school = ref({
  teachers: [],
  school_photos: []
})

const showModal = reactive({
  editSchool: false,
  createImage: false
})

const courses = ref([])
const categories = ref([])

const getData = async () => {
  school.value = (await getSchoolData(route.params.school_id)).data
}

onMounted(async () => {
  school.value = (await getSchoolData(route.params.school_id)).data
  courses.value = await schoolHaveCourses(route.params.school_id, { status: 'published' })
  const list = []
  courses.value.filter(course => {
    if (!list.includes(course.category_name)) list.push(course.category_name)
  })
  if (courses.value.length === 0) courses.value = null
  categories.value = list
  document.title = `${school.value.name} - A-Class`
  showModal.editSchool = schoolAdmin.value && route.params.edit ? true : !true
})

// Check Have This School
const schoolAdmin = computed(() => {
  if (!user.value.data.id || !school.value.id) return false
  return user.value.data.schools.some((schoolItem) => schoolItem.id === school.value.id && schoolItem.permission.role === 'admin')
})
</script>

<style scoped lang="scss">
.course-card {
  min-width: 420px;
}

.gallery {
  & .left,
  & .addPhotoBtn {
    display: flex;
    align-items: center;
    grid-gap: 30px;
  }

  & .addPhotoBtn {
    opacity: .85;
    grid-gap: 8px;
    transition: all .3s ease-in-out;
    &:hover {
      opacity: 1;
      & svg {
        transform: rotate(90deg);
      }
    }
    span {
      font-weight: 500;
      font-size: 16px;
      color: #0082FA;
    }
    & svg {
      transition: all .3s ease-in-out;
    }
  }
}
</style>
