<template>
<div class="program">
  <div
    class="program-items"
    :class="{activeShowMoreBtn: activeShowMoreBtn}"
  >
    <div
      class="program-item"
      v-for="(lesson, index) of lessons"
      :key="lesson"
    >
      <div class="program-block">
        <h5 class="program-block-num"><span>{{ index + 1 }}</span></h5>
        <h3 class="program-block-title">{{ lesson.name }}</h3>
      </div>
      <div class="program-content">
        <div class="program-content-title">Описание</div>
        <p class="program-content-description">{{ lesson.description }}</p>
      </div>
    </div>
  </div>
  <div class="program-pagination" v-if="activeShowMoreBtn">
    <p class="showed-and-total">Показать еще 5 уроков</p>
    <Button name="Показать еще 5 программ" type="default-outlined dark"></Button>
  </div>
</div>
</template>

<script setup>
import { onMounted, ref } from 'vue'

const props = defineProps({
  lessonsData: {
    type: Array,
    default: null
  }
})
const lessons = ref(props.lessonsData)
const activeShowMoreBtn = ref(props.lessonsData.length > 5)

onMounted(() => {
  lessons.value = props.lessonsData
})

</script>

<style scoped lang="scss">
.program {
  &-items {
    display: flex;
    flex-direction: column;
    grid-gap: 32px;
    &.activeShowMoreBtn {
      margin-bottom: 0;
    }
    & .program-item:last-child {
      padding-bottom: 0;
      border-bottom: none;
    }
  }
  &-item {
    display: grid;
    grid-template-columns: 35% 1fr;
    grid-gap: 90px;

    border-bottom: 1px solid #DFDFDF;
    padding-bottom: 50px;
  }
  &-block {
    display: flex;
    grid-gap: 24px;
    &-num {
      display: inline-block;
      height: 28px;
      min-width: 28px;
      padding: 5px;

      display: flex;
      justify-content: center;
      align-items: center;

      border: 1px solid #161931;
      border-radius: 5px;
      margin-top: 3px;

      & span {
        font-weight: 400;
        font-size: 14px;
        color: #161931;
      }
    }
    &-title {
      font-weight: 600;
      font-size: 28px;
      line-height: 1.4;
      color: #161931;
      margin-bottom: 12px;
    }
  }
  &-content {
    &-title {
      font-weight: 500;
      font-size: 18px;
      color: #161931;
      margin-bottom: 14px;
    }
    &-description {
      font-weight: 400;
      font-size: 13px;
      line-height: 1.3;
      color: #6F6F6F;
    }
  }
  &-pagination {
    display: flex;
    justify-content: center;
    position: relative;

    & .showed-and-total {
      font-weight: 500;
      font-size: 14px;
      color: #A6A8B4;

      position: absolute;
      left: 0;
      top: 50%;
      z-index: 1;
      transform: translate(0, -50%);
    }
  }
}
</style>
