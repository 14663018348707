<template>
  <header class="header">
    <div class="wrapper wrapper-landing landing-header">
      <div class="header-left">
        <router-link to="/" class="header-logo"><SvgIcon name="logo" :viewboxWidth="149" :viewboxHeight="39" /></router-link>

        <nav class="header-links" v-if="!showSearch">
          <router-link to="/schools">Школы</router-link>
          <router-link to="/courses">Курсы</router-link>
          <router-link to="/for-business">Для преподователей</router-link>
          <button
            class="search"
            @click="showingSearch"
          >
            <SvgIcon name="search" :viewboxHeight="23" :viewboxWidth="22" :width="22" :height="22"/>
          </button>
        </nav>

        <Search
          v-else
          @hide="() => { showSearch = false }"
        />
      </div>

      <div class="header-right">
        <ProfileDropdown v-if="logged()"/>
        <div class="login" v-else>
          <router-link to="/authorization/login"><Button name="Войти" type="default-outlined dark"/></router-link>
          <router-link class="edit" to="/authorization/registration"><Button name="Регистрация"/></router-link>
          <div class="burger-menu">
            <button>
              <SvgIcon name="burger-menu" :viewboxHeight="37" :viewboxWidth="36"/>
            </button>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script setup>
import ProfileDropdown from '../../Profile/ProfileDropdown.vue'
import Search from './Search/Search.vue'

import { useAuth } from '@/modules/auth'
import { ref } from 'vue'

const { logged } = useAuth()

const showSearch = ref(false)
const showingSearch = () => {
  setTimeout(() => {
    showSearch.value = true
  }, 10)
}
</script>

<style scoped lang="scss">
.wrapper {
  align-items: center;
  margin-bottom: 0 !important;
}
.header {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;

  padding-top: 10px;
  margin: 10px 0 50px;

  & .landing-header {
    position: relative;
  }

  &-left {
    display: flex;
    align-items: center;
    gap: 65px;
  }
  &-logo svg {
    display: block;
    height: 39px !important;
    width: 149px !important;
  }
  &-right .login {
    display: flex;
    align-items: center;
    gap: 15px
  }
  &-links {
    display: flex;
    align-items: center;
    gap: 40px;

    & a {
      font-weight: 400;
      font-size: 16px;
      line-height: 1.2;
      color: #161931;
      transition: all .3s ease-in-out;

      &:hover {
        color: #0082FA;
      }
    }

    & .router-link-active{
      position: relative;
      &::after {
        content: '';
        display: block;
        height: 2px;
        width: calc(100% + 3px);

        position: absolute;
        bottom: -10px;
        left: 50%;
        transform: translateX(-50%);
        background-color: #0082FA;
      }
    }
  }
}
button.search {
  & svg {
    stroke: #161931;
    stroke-width: 1.5;
    transition: all .3s ease-in-out;
  }

  &:hover svg {
    stroke: #0082FA;
    stroke-width: 2;
  }
}
.burger-menu {
  display: none;
  & svg {
    height: 36px !important;
    width: 36px !important;
  }
}

// ------------ Adaptive ------------
@media (max-width: 1440px) {
  .header {
    &-left {
      gap: 40px;
    }
    &-links {
      gap: 30px;
    }
  }
}
@media (max-width: 1200px) {
  .header {
    margin: 10px 0 50px;
    &-logo svg {
      height: 32px !important;
      width: 120px !important;
    }
    &-links {
      display: none;
    }
    &-right .login a.edit {
      display: none;
    }
  }
  .burger-menu {
    display: block;
  }
}
@media (max-width: 992px) {
  .header {
    margin: 10px 0 40px;
    &-logo svg {
      height: 32px !important;
      width: 120px !important;
    }
    &-links {
      display: none;
    }
    &-right .login a.edit {
      display: none;
    }
  }
  .burger-menu {
    display: block;
  }
}

// ------------ Transitions ------------
.nav-link-enter-active,
.nav-link-leave-active {
  transition: all .3s ease;
}
.nav-link-enter-from,
.nav-link-leave-to {
  opacity: 0;
  transform: translateX(-100%);
}
</style>
