<template>
  <div class="filters" v-show="visible">
    <Select
      v-if="courseOptions"
      v-model="filters.course_id"
      :options="courseOptions"
      optionNameKey="name"
      optionValueKey="id"
      placeholder="Выберите курс"
    />
    <Select
      v-model="filters.order_by"
      :options="orderByOptions"
      optionNameKey="name"
      optionValueKey="value"
      placeholder="Сортировка"
    />
  </div>
</template>

<script setup>
import Select from '@/components/common/Select.vue'

import { ref, onMounted, reactive, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import debounce from '@/helpers/debounce'

import { useAuth } from '@/modules/auth'
import { getSchoolCourses } from '@/actions/uiLanding/school'

defineProps({
  visible: Boolean
})

const { selectedSchool } = useAuth()
const route = useRoute()
const router = useRouter()

const filters = reactive({
  order_by: null,
  course_id: null
})

watch(
  () => filters,
  debounce(() => router.push({ query: { ...route.query, ...filters } })),
  {
    deep: true
  }
)

const orderByOptions = [
  {
    name: 'По названию',
    value: 'name'
  },
  {
    name: 'По дате',
    value: 'id'
  }
]

const courseOptions = ref(null)

onMounted(async () => {
  courseOptions.value = (await getSchoolCourses(selectedSchool.value.id)).data.data
})
</script>

<style scoped>
.filters {
  display: flex;
  align-items: flex-end;
  gap: 20px;
  margin-bottom: 20px;
}
</style>
