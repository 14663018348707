<template>
  <Sidebar :data="data">
    <!-- <div class="course" v-if="lessonsIsActive() && data.lessons_data.length > 0">
      <div class="header" :style="{ backgroundColor: headerColor }">
        <img :src="getFileUrl(data.course_data.course_logo)" class="course-logo" alt="" v-show="true" @load="setBackgroundColor" crossorigin="anonymous">
        <h1 class="course-name">{{ data.course_data.name }}</h1>
        <router-link :to="'/lesson/' + route.params.course_id +  '/unit/' + data.lessons_data[0].id"><Button name="Начать курс"/></router-link>
      </div>
      <div class="content">{{ data.course_data.description }}</div>
    </div> -->
    <router-view/>
  </Sidebar>
</template>

<script setup>
import Sidebar from '@/components/uiLearning/layouts/Sidebar.vue'
import { onMounted, provide, reactive, toRef, watch } from 'vue-demi'
import { useRoute, useRouter } from 'vue-router'

import { useAuth } from '@/modules/auth'
import { useCourseProgress, useNextLesson } from '@/modules/learning.js'

// import getBackgroudColor from '@/helpers/getBackgroundColor'
// import getFileUrl from '@/helpers/getFileUrlByDate'

import { getCourseLessons } from '@/actions/uiLearning/lesson'
import { getUserTakes } from '@/actions/uiLearning/take'
import { getCourse } from '@/actions/uiBusiness/courses'

import checkFullAccess from '@/helpers/checkFullAccess.js'

const { getFinishedLessons, setFinishedLessons } = useCourseProgress()
const { setNextLesson } = useNextLesson()
const { user } = useAuth()
const route = useRoute()
const router = useRouter()

const data = reactive({
  course_data: {},
  lessons_data: [],
  takes_data: [],
  finished_lessons_count: () => getFinishedLessons()
})

const hasInitialRoute = route.params.lesson_id || route.params.homework_id
// const headerColor = ref('var(--hover-color)')

// const lessonsIsActive = () => {
//   return route.path === `/lesson/${route.params.course_id}`
// }

// const setBackgroundColor = event => {
//   const img = event.target
//   headerColor.value = getBackgroudColor(img, 4)
// }

provide('courseData', toRef(data, 'course_data'))

onMounted(async () => {
  data.course_data = (await getCourse(route.params.course_id)).data
  const fullAccess = checkFullAccess(route.params.course_id, user.value.data)

  data.lessons_data = await getCourseLessons(route.params.course_id, fullAccess)
  if (user.value.data?.id) {
    data.takes_data = await getUserTakes(user.value.data.id)
  }
  setFinishedLessons(route.params.course_id)
  setNextLesson(Number(route.params.homework_id), data.lessons_data)
  if (hasInitialRoute) return
  router.push(`/lesson/${route.params.course_id}/unit/${data.lessons_data[0].id}`)
})

watch(
  () => user.value,
  async () => {
    data.takes_data = await getUserTakes(user.value.data.id)
  },
  {
    deep: true
  }
)

watch(
  () => route.params.course_id,
  () => {
    setNextLesson(Number(route.params.homework_id), data.lessons_data)
  }
)
</script>

<style scoped>
.header {
  width: 100%;
  height: 320px;
  background-color: var(--hover-color);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}
.course {
  display: flex;
  flex-direction: column;
}
.content {
  padding: 25px;
}
.course-name{
  z-index: 3;
}
.course-logo{
  position: absolute;
  filter: blur(10px);
  width: 350px;
  height: 300px;
}
</style>
