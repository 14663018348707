<template>
  <Modal :hide="hide" class="modal-new-request">
    <Form @finish="createApplication">
      <div class="modal-header">
        <span class="title">Подать заявку на вывод денежных средств</span>
      </div>
      <div class="modal-body">
        <div>
          <div class="modal-label">Баланс школы</div>
          <div class="schoolBalance">
            <span class="price">{{formatCurrency(schoolBalance)}} тг</span>
          </div>
        </div>
        <div>
          <div class="modal-label">Наименование школы</div>
          <input type="text" v-model="schoolName" disabled/>
        </div>

        <div class="need-amount">
          <Input type="number" name="name" placeholder="Запрашиваемая сумма (тг) " v-model="formData.amount" required />
          <p class="total">Поступит на ваш счет с учетом комиссии: <b>{{formatCurrency(total)}} тг </b></p>
        </div>
      </div>
      <div class="modal-footer">
        <Button name="ОТПРАВИТЬ ЗАПРОС" :loading="loading" htmlType="submit" />
        <Button name="ОТКЛОНИТЬ" type="cancel" @click="hide" />
      </div>
    </Form>
  </Modal>
</template>

<script setup>
import Modal from '@/components/common/Modal.vue'
import Form from '@/components/common/Form.vue'

import { onMounted, ref } from 'vue-demi'
import { useAuth } from '@/modules/auth'
import { useToast } from '@/modules/toast.js'

import formatCurrency from '@/helpers/formatCurrency.js'
import { getSchoolIncome, postApplication } from '@/actions/uiBusiness/applications'
import { computed } from '@vue/reactivity'

const props = defineProps({
  hide: null,
  userCardId: null
})

const emit = defineEmits(['reloadTable'])
const { toast } = useToast()

const { selectedSchool } = useAuth()
const loading = ref(false)

const formData = ref(
  {
    user_card_id: props.userCardId,
    school_id: selectedSchool.value.id,
    amount: null
  }
)

const schoolName = selectedSchool.value.name
const schoolBalance = ref(0)
const total = computed(() => {
  if (formData.value.amount === null) return 0
  if (formData.value.amount <= 50000) return formData.value.amount - formData.value.amount * 0.1
  if (formData.value.amount <= 100000) return formData.value.amount - formData.value.amount * 0.07
  if (formData.value.amount <= 150000) return formData.value.amount - formData.value.amount * 0.07
  if (formData.value.amount <= 200000) return formData.value.amount - formData.value.amount * 0.05
  return formData.value.amount - formData.value.amount * 0.05
})

onMounted(
  async () => {
    schoolBalance.value = (await getSchoolIncome(selectedSchool.value.id)).data
  }
)

const createApplication = async () => {
  loading.value = true
  try {
    await postApplication(formData.value)
    toast({
      message: 'Заявка успешно отправлена',
      type: 'success'
    })
    emit('reloadTable')
    props.hide()
  } finally {
    loading.value = false
  }
}

</script>

<style scoped>
/* Модальное окно нового контакта */
.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modal-header .title {
  font-size: 24px;
  font-weight: bold;
  color: #161931;
}

.modal-header .close-modal {
  cursor: pointer;
}

.modal-body {
  padding: 40px 0 20px 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.modal-body input {
  font-size: 16px;
  padding: 15px 17px;
  width: 100%;
}

.modal-body .password-input {
  width: 100%;
  display: flex;
  gap: 20px;
}

.modal-body .password-input input {
  width: 100%;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}

button.labeled {
  margin-top: 10px;
}

.modal-body .modal-label{
  font-weight: 400;
  font-size: 14px;
  color: #161931;
  margin-bottom: 5px;
}

.schoolBalance{
  display: inline-block;
  padding: 16px 18px 15px;
  background: #0082FA;
  border-radius: 3px;
}

.schoolBalance .price{
  font-weight: 600;
  font-size: 16px;
  color: #FFFFFF;
}

.schoolBalance .total b{
  font-weight: 500;
}
</style>
<style>
.modal-new-request .container {
  min-width: 660px;
}
.modal-new-request .modal-footer button{
  height: 50px;
}
.modal-new-request button.cancel{
  background: #FA7171 !important;
}
.modal-new-request button.cancel span{
  color: #fff !important;
}
</style>
