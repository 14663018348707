<template>
  <Header type="ForTeachers"/>
  <section class="banner">
    <div class="wrapper">
      <div class="tagline">
        <h1>Делитесь знанием и зарабатывайте на A Class Online</h1>
        <p>Станьте партнером и монетизируйте свои знания вместе с нами</p>
        <router-link to="#business-registration" v-if="!logged()">
          <Button class="btn" name="Начать сейчас" type="outlined" @click="saveRoute('/for-business#business-registration')">
            <SvgIcon name="arrow-right" :viewboxHeight="32" :viewboxWidth="32" :width="32" :height="32" fill="#fff"/>
          </Button>
        </router-link>
      </div>
      <img class="svg" src="/img/forTeachers_banner.svg" alt="" draggable="false">
    </div>
  </section>
  <div class="feature wrapper" id="feature">
    <h2>Для преподавателей</h2>
    <div class="items">
      <div class="item">
        <img src="/img/tmp/learn_how_you_like.svg" alt="">
        <div class="title">Учите, как вам нравится</div>
        <div class="text">Сами выбирайте курсы для публикации так, как вам захочется, а также сохраняйте полный контроль на своими материалами.</div>
      </div>
      <div class="item">
        <img src="/img/tmp/inspire_students.svg" alt="">
        <div class="title">Вдохновляйте студентов</div>
        <div class="text">Делитесь знаниями и помогайте своим студентам находить новые увлечения, получать новые навыки и добиваться успехов в карьере.</div>
      </div>
      <div class="item">
        <img src="/img/tmp/get_rewarded.svg" alt="">
        <div class="title">Получайте вознаграждения</div>
        <div class="text">Расширяйте профессиональные связи, расширяйте знания и зарабатывайте деньги за каждую регистрацию на платный курс.</div>
      </div>
    </div>
  </div>
  <div class="sentence">
    <div class="wrapper">
      <h1 class="title">Зачем платить больше когда есть A Class Online</h1>
      <p class="subtitle">Мы предлагаем платформу с востребованными возможностями. Пользование системой БЕСПЛАТНО!</p>
      <RouterLink to="#business-registration">
        <Button name="Начать знакомство!" />
      </RouterLink>
    </div>
  </div>
  <div id="business-registration" class="advantages wrapper">
    <h1 class="title" id="advantages">Преимущества использования нашей платформы:</h1>
    <div class="inner">
      <div class="content">
        <div class="item">
          <img src="/img/tmp/advantages_01.svg" alt="">
          <div class="text">Бесплатное пользование системой</div>
        </div>
        <div class="item">
          <img src="/img/tmp/advantages_02.svg" alt="">
          <div class="text">Неограниченное количество студентов, которых Вы можете зарегистрировать на сайте</div>
        </div>
        <div class="item">
          <img src="/img/tmp/advantages_03.svg" alt="">
          <div class="text">Бесплатное использование платежной системы для оплаты</div>
        </div>
        <div class="item">
          <img src="/img/tmp/advantages_04.svg" alt="">
          <div class="text">Служба поддержки 24/7</div>
        </div>
        <div class="item">
          <img src="/img/tmp/advantages_05.svg" alt="">
          <div class="text">Неограниченное количество памяти для курсов</div>
        </div>
        <div class="item">
          <img src="/img/tmp/advantages_06.svg" alt="">
          <div class="text">Легкий и понятный пользовательский интерфейс</div>
        </div>
      </div>
      <BusinessRegistration />
    </div>
  </div>
  <Footer />
</template>

<script setup>
import Header from '@/components/uiLanding/layouts/Header.vue'
import Footer from '@/components/uiLanding/layouts/Footer.vue'
import BusinessRegistration from '@/components/uiLanding/BusinessRegistration.vue'
import { useAuth } from '@/modules/auth'

const { logged } = useAuth()
// /business-registration
const saveRoute = route => {
  localStorage.setItem('savedRoute', route)
}
</script>

<style scoped>
.banner {
  background: #FF7EB8;
}
.banner .wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.banner .tagline h1 {
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 36px;
  line-height: 43px;
  color: #fff;
}
.banner .tagline p {
  font-size: 18px;
  line-height: 21px;
  color: #fff;
  margin-bottom: 62px;
}
.banner .tagline a {
  display: inline-block;
}
h1{
  font-weight: bold;
  font-size: 48px;
  letter-spacing: 0.02em;
  color: var(--primary-color);
}
h2 {
  font-weight: bold;
  font-size: 36px;
  line-height: 43px;
  letter-spacing: 0.02em;
  color: var(--primary-color);
  margin-bottom: 50px;
}
.feature,
.sentence,
.advantages,
.plans{
  padding: 120px 0;
}
.feature{
  padding-top: 60px;
}
.feature .items{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 70px;
}
.feature img{
  display: block;
  height: 150px;
  width: 150px;
  margin: 0 auto 40px;
  margin-bottom: 40px;
}
.feature .title{
  font-weight: 600;
  font-size: 24px;
  color: var(--primary-color);
  margin-bottom: 16px;
}
.feature .text{
  font-size: 18px;
  color: var(--primary-color);
}
.sentence{
  padding-top: 110px;
  background: var(--primary-color);
}
.sentence .wrapper{
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.sentence .title,
.sentence .subtitle{
  color: #fff;
}
.sentence .title{
  margin-bottom: 20px;
}
.sentence .subtitle{
  max-width: 525px;
  font-size: 18px;
  margin-bottom: 50px;
}
.advantages .inner{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px;
}
.advantages .content .item:last-child{
  margin-bottom: 0 !important;
}
.advantages .item{
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.advantages .item img{
  display: block;
  width: 60px;
  height: 60px;
  margin-right: 20px;
}
.advantages .text{
  font-size: 18px;
  color: #000;
}
.advantages .preview img{
  display: block;
  width: 100%;
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.1));
}
.advantages button{
  margin: 0 auto;
}
.advantages .title {
  margin-bottom: 50px;
  font-size: 36px;
}
</style>
