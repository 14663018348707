<template>
  <div class="wrapper-system courses" :class="{list: !viewType}">
    <div v-if="!isEmpty">
      <h1>
        <span>Мои курсы</span>
        <ViewTypeControl v-model="viewType" />
      </h1>

      <div class="courses-table">
        <CourseCard
          :class="{list: !viewType}"
          v-for="course of courses.length > 0 ? courses : 12"
          :key="course"
          :course="course"
          :viewTypeGrid="viewType"
        />
      </div>

      <div v-if="coursesFinihed.length !== 0">
        <h1><span>Завершенные курсы</span></h1>
        <div class="courses-table">
          <CourseFinishedCard
            :class="{list: !viewType}"
            v-for="course of coursesFinihed"
            :key="course"
            :course="course"
          />
        </div>
      </div>
    </div>

    <NotCourses v-else />
  </div>
</template>

<script setup>
import CourseFinishedCard from '@/components/uiClient/Courses/CourseFinishedCard.vue'
import CourseCard from '@/components/uiClient/Courses/CourseCard.vue'
import NotCourses from '@/components/uiClient/Courses/NotCourses.vue'
import ViewTypeControl from '@/components/common/ViewTypeControl.vue'

import { onMounted, ref } from 'vue-demi'

import { getUserCourses } from '@/actions/uiClient/courses'

const courses = ref([])
const coursesFinihed = ref([])
const isEmpty = ref(false)
const viewType = ref(true)

const finishedLessonsCount = (lessons) => {
  let count = 0
  for (const lesson of lessons) {
    if (lesson.finished_at !== null) count++
  }
  return count
}

onMounted(async () => {
  courses.value = (await getUserCourses()).data
  coursesFinihed.value = courses.value

  if (courses.value.length === 0) isEmpty.value = true
  courses.value.forEach(course => {
    course.lessons = course.lessons.filter((lesson) => lesson.status === 'published')
    course.lessons_finished = finishedLessonsCount(course.lessons)
  })

  const noFinishedCourse = []
  coursesFinihed.value = courses.value.filter(course => {
    const finishedLessons = course.lessons.filter(lesson => lesson.finished_at !== null)

    if (course.lessons.length === finishedLessons.length) return course
    else noFinishedCourse.push(course)
  })
  courses.value = noFinishedCourse
})
</script>

<style scoped lang="scss">
h1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
h1 span {
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #161931;
}
.disabled {
  pointer-events: none;
}

.courses {
  &.list .courses-table {
    grid-template-columns: 1fr;
  }
  &-table {
    display: grid;
    grid-template-columns: repeat( auto-fit, minmax(250px, 1fr));
    grid-gap: 20px;
    background: #FFFFFF;
    border: 0.5px solid #161931;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 70px;
  }
}
</style>
